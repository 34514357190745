<!--<template>-->
<!--  <div>-->
<!--    <PageLoader />-->
<!--    <v-app>-->
<!--      <v-layout wrap class="mainbg4">-->
<!--        <v-main>-->
<!--          &lt;!&ndash; <AppHeader /> &ndash;&gt;-->
<!--          <v-flex xs12 sm12>-->
<!--            <router-view :key="$route.fullPath" v-bind:storage="sideNav" />-->
<!--          </v-flex>-->
<!--        </v-main>-->
<!--      </v-layout>-->
<!--    </v-app>-->
<!--  </div>-->
<!--</template>-->

<!--<script>-->
<!--// import axios from "axios";-->
<!--import "./assets/style/font.css";-->
<!--import "./assets/style/style.css";-->
<!--// import AppHeader from "@/components/Common/appHeader";-->

<!--export default {-->
<!--  name: "App",-->

<!--  components: {-->
<!--    // AppHeader,-->
<!--  },-->
<!--  data: () => ({-->
<!--    //-->
<!--    sideNav: false,-->
<!--  }),-->
<!--  // beforeMount() {-->
<!--  //   if (typeof localStorage.getItem("token") == "string") {-->
<!--  //     this.$store.commit("appLoading", true);-->
<!--  //     axios({-->
<!--  //       method: "POST",-->
<!--  //       url: "/media/admin/profile",-->
<!--  //       headers: {-->
<!--  //         "token": localStorage.getItem("token"),-->
<!--  //       },-->
<!--  //     })-->
<!--  //       .then((response) => {-->
<!--  //         if (response.data.status) {-->
<!--  //           this.$store.commit("appLoading", false);-->
<!--  //           this.$store.commit("userData", response.data.data);-->
<!--  //           this.$store.commit("userType", response.data.role);-->
<!--  //           // this.$store.commit("menu", response.data.menu);-->
<!--  //         }-->
<!--  //       })-->
<!--  //       .catch((err) => {-->
<!--  //         this.$store.commit("appLoading", false);-->
<!--  //         console.log(err);-->
<!--  //       });-->
<!--  //   }-->
<!--  // },-->
<!--};-->
<!--</script>-->


<template>
  <div>
    <PageLoader />
    <v-app>
      <v-layout wrap class="mainbg4">
        <v-main>
          <v-flex xs12 sm12>
            <router-view :key="$route.fullPath" v-bind:storage="sideNav" />
          </v-flex>
        </v-main>
      </v-layout>
      <div v-if="showInstallPrompt" class="install-prompt">
        <p>Install our app for a better experience!</p>
        <button @click="installApp">Install</button>
      </div>
    </v-app>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      showInstallPrompt: false,
      deferredPrompt: null,
      sideNav: false,
    };
  },
  created() {
    window.addEventListener("beforeinstallprompt", (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
      // Update UI notify the user they can install the PWA
      this.showInstallPrompt = true;
    });

    window.addEventListener("appinstalled", () => {
      // Hide the app-provided install promotion
      this.showInstallPrompt = false;
      this.deferredPrompt = null;
      console.log("PWA was installed");
    });
  },
  methods: {
    installApp() {
      this.showInstallPrompt = false;
      if (this.deferredPrompt) {
        this.deferredPrompt.prompt();
        this.deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === "accepted") {
            console.log("User accepted the A2HS prompt");
          } else {
            console.log("User dismissed the A2HS prompt");
          }
          this.deferredPrompt = null;
        });
      }
    },
  },
};
</script>

<style>
.install-prompt {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}
.install-prompt button {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.install-prompt button:hover {
  background-color: #0056b3;
}
</style>
